import { useEffect, useState } from 'react'
import { EventTimeZoneTypeEnum } from 'common/components/entities/Calendar/constants'
import { useBooking } from 'common/components/entities/Calendar/hooks/use-booking'
import { useEvent } from 'common/components/entities/Calendar/hooks/use-event'
import {
  getStartEndDates,
  joinDateWithTimeSlot,
} from 'common/components/entities/Calendar/utils'
import { getLocalTimeZone } from 'common/utils/date-utils'
import { getUrlPathList } from 'common/utils/urlUtils'
import { rescheduleBooking } from 'publisher/api/booking-calendar-api'
import { useEventTimeSlots } from 'publisher/hooks/use-event-time-slots'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import { useCalendarSelectors } from './use-calendar-selectors'

export const useRescheduleBooking = () => {
  const {
    selectedDate,
    selectedTimeSlot,
    selectedTimeZone,
    selectedLocationId,
    handleChangeField,
  } = useCalendarSelectors()

  // https://${domain_name}/booking-calendar/${token}/reschedule
  const token = getUrlPathList(window.location.href)[1]

  const { booking, isFetching } = useBooking(token)

  const { event, isFetching: isEventFetching } = useEvent({
    eventId: booking?.eventId,
    scope: 'publisher',
  })
  const { timeSlots = [], isFetching: isTimeSlotsFetching } =
    useEventTimeSlots()

  useEffect(() => {
    if (booking && event) {
      handleChangeField('eventId', event.id!)
      handleChangeField('ownerId', event.ownerId)
      handleChangeField('eventLocationId', event.locations?.[0]?.id)
      handleChangeField(
        'timeZone',
        event.displayTimezoneType === EventTimeZoneTypeEnum.Local
          ? getLocalTimeZone()
          : event.displayTimezone || getLocalTimeZone(),
      )
    }
  }, [booking, event])

  const onMonthSelect = (month: Date) => {
    const { startDate, endDate } = getStartEndDates(month)
    handleChangeField('startDate', startDate)
    handleChangeField('endDate', endDate)
  }

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<unknown>(null)
  const [isRescheduled, setIsRescheduled] = useState(false)

  const handleRescheduleBooking = async () => {
    try {
      setIsLoading(true)
      await rescheduleBooking({
        timezone: selectedTimeZone,
        token,
        bookingId: booking!.id,
        date: joinDateWithTimeSlot(selectedDate, selectedTimeSlot),
      })
      setIsRescheduled(true)
    } catch (error) {
      setErrors(error)
    } finally {
      setIsLoading(false)
    }
  }

  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const globalMobileFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalFontFamily = usePage(pageSelectors.getGlobalTextFontFamily)

  return {
    selectedDate,
    selectedTimeSlot,
    selectedTimeZone,
    selectedLocationId,
    handleChangeField,
    event,
    booking,
    isEventFetching,
    timeSlots,
    isTimeSlotsFetching,
    onMonthSelect,

    handleRescheduleBooking,
    isLoading: isFetching || isLoading,
    errors,
    isRescheduled,

    globalFontSize,
    globalMobileFontSize,
    globalFontFamily,
  }
}
