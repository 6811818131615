import { useEffect, useState } from 'react'
import { BookingInterface } from 'common/components/entities/Calendar/calendar-interface'
import { getBooking } from 'publisher/api/booking-calendar-api'
import { optInSelectors, useTypedSelector } from 'publisher/store'
import { getLocalTimeZone } from 'common/utils/date-utils';

export const useBooking = (token: string) => {
  const [booking, setBooking] = useState<BookingInterface>()
  const [error, setError] = useState<unknown>(null)
  const [isFetching, setIsFetching] = useState(false)
  const selectedTimeZone = useTypedSelector(state =>
    optInSelectors.getBookingFieldValue(state.optIn, 'timeZone'),
  )

  const fetch = async () => {
    setIsFetching(true)
    try {
      const data = await getBooking({
        token,
        timezone: selectedTimeZone || getLocalTimeZone(),
      })
      setBooking(data.data)
    } catch (error) {
      setError(error)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    if (token) fetch()
  }, [token, selectedTimeZone])

  return { booking, isFetching, error, fetch }
}
