import styled, { css, CSSObject } from 'styled-components'
import { RemotePopupBodyInterface } from 'common/types/remote-popup-body-interface'
import {
  backgroundColorCss,
  backgroundCss,
  borderCss,
  boxShadowCss,
  colorCss,
  fontCss,
  FontProps,
  mobileBackgroundColorCss,
  mobileBackgroundCss,
  mobileBorderCss,
  mobileBoxShadowCss,
  mobileColorCss,
  mobileFontCss,
} from 'common/utils/styleUtilsNew'

export interface RemotePopupBodyUiProps
  extends FontProps,
    Omit<
      RemotePopupBodyInterface,
      | 'htmlAttrId'
      | 'childIds'
      | 'id'
      | 'type'
      | 'showClose'
      | 'closedDays'
      | 'openAutomatically'
      | 'delay'
      | 'openOnExit'
      | 'openOnMobileAutomatically'
      | 'mobileDelay'
      | 'parentId'
      | 'padding'
      | 'mobilePadding'
    > {
  maxHeight?: CSSObject['height']
  overflow?: CSSObject['overflow']
  backgroundImage?: string | null
  mobileBackgroundImage?: string | null
  color?: string
  mobileColor?: string
}

export const RemotePopupBodyUi = styled.div<RemotePopupBodyUiProps>`
  width: 100%;
  max-width: 600px; /* need for iframe width detection */
  border-radius: 6px;
  position: relative;
  transition: transform 0.3s ease-out;
  display: block;

  ${backgroundCss}
  ${backgroundColorCss}
  ${borderCss}
  ${boxShadowCss}
  ${fontCss}
  ${colorCss}

  max-height: ${p => p.maxHeight}
  overflow: ${p => p.overflow}

  ${p =>
    p.backgroundImage &&
    css`
      background-image: ${p.backgroundImage};
    `};

  @media only screen and (max-width: 500px) {
    max-width: 350px;
    width: 100%;
  }

  ${p => p.theme.phone} {
    ${p =>
      p.mobileBackgroundImage &&
      css`
        background-image: ${p.mobileBackgroundImage};
      `};

    ${mobileBackgroundCss}
    ${mobileBackgroundColorCss}
    ${mobileBorderCss}
    ${mobileBoxShadowCss}
    ${mobileFontCss}
    ${mobileColorCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      background-image: ${p.mobileBackgroundImage || 'none'};

      ${mobileBackgroundCss}
      ${mobileBackgroundColorCss}
      ${mobileBorderCss}
      ${mobileBoxShadowCss}
      ${mobileFontCss}
      ${mobileColorCss}
    `}
`
