import React from 'react'
import { useTranslation } from 'react-i18next'
import FieldErrorUi from 'common/components/core/FieldErrors/ui/FieldErrorUi'
import { CalendarStep } from 'common/components/entities/Calendar/calendar-step'
import {
  CommonCalendarButtonUi,
  CommonCalendarButtonWrapper,
} from 'common/components/entities/Calendar/calendar-step/ui/common-calendar-button-ui'
import { EventTimeZoneTypeEnum } from 'common/components/entities/Calendar/constants'
import { ThankYouStep } from 'common/components/entities/Calendar/thank-you-step'
import {
  CommonBookingCalendarWrapperUi,
  CommonBookingCalendarContainerUi,
} from 'common/components/entities/Calendar/ui/common-calendar-container-ui'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { useCalendarErrors } from './use-calendar-step-errors'
import { useRescheduleBooking } from './use-reschedule-booking'

interface PreviewBookingCalendarProps {
  entity: BookingCalendarInterface
}

export const RescheduleBookingCalendar = ({
  entity,
}: PreviewBookingCalendarProps) => {
  const { t } = useTranslation()

  const {
    event,
    booking,
    timeSlots,
    isEventFetching,
    isTimeSlotsFetching,
    selectedDate,
    selectedTimeZone,
    selectedTimeSlot,
    selectedLocationId,
    handleChangeField,
    onMonthSelect,

    handleRescheduleBooking,
    isLoading,
    errors: rescheduleErrors,
    isRescheduled,

    globalFontFamily,
    globalMobileFontSize,
    globalFontSize,
  } = useRescheduleBooking()

  const { errors, isErrorsVisible, setIsErrorsVisible } = useCalendarErrors()

  const onRescheduleBooking = () => {
    if (errors.length) {
      if (!isErrorsVisible) setIsErrorsVisible(true)
      return
    }
    handleRescheduleBooking()
  }

  return (
    <CommonBookingCalendarWrapperUi style={{ padding: '5px 0' }}>
      <CommonBookingCalendarContainerUi
        isThankYouStep={isRescheduled}
        padding={entity.padding}
        margin={entity.margin}
        mobilePadding={entity.mobilePadding}
        mobileMargin={entity.mobileMargin}
        color={entity.color}
        mobileColor={entity.mobileColor}
        fontFamily={globalFontFamily}
        fontSize={globalFontSize}
        mobileFontSize={globalMobileFontSize}
      >
        {isRescheduled ? (
          <ThankYouStep
            entity={entity}
            selectedDate={selectedDate}
            selectedTimeSlot={selectedTimeSlot}
            selectedLocationId={selectedLocationId}
            selectedTimeZone={selectedTimeZone}
            event={event}
            hasThankYouStepButton={false}
            color={entity.color}
            mobileColor={entity.mobileColor}
            fontFamily={globalFontFamily}
            fontSize={globalFontSize}
            mobileFontSize={globalMobileFontSize}
          />
        ) : (
          <>
            <CalendarStep
              availabilitySlots={timeSlots}
              selectedDate={selectedDate}
              onMonthSelect={onMonthSelect}
              onDateSelect={date => {
                handleChangeField('date', date)
                handleChangeField('timeSlot', '')
              }}
              selectedTimeSlot={selectedTimeSlot}
              onTimeSlotSelect={timeSlot =>
                handleChangeField('timeSlot', timeSlot)
              }
              selectedTimeZone={selectedTimeZone}
              onTimeZoneSelect={tz => {
                if (
                  event?.displayTimezoneType !== EventTimeZoneTypeEnum.Fixed
                ) {
                  handleChangeField('timeZone', tz)
                }
              }}
              selectedLocationId={selectedLocationId}
              onLocationSelect={locationId => {
                handleChangeField('eventLocationId', locationId)
              }}
              event={event}
              booking={booking}
              isLoading={isEventFetching || isTimeSlotsFetching}
              entity={entity}
              color={entity.color}
              mobileColor={entity.mobileColor}
              fontFamily={globalFontFamily}
              fontSize={globalFontSize}
              mobileFontSize={globalMobileFontSize}
            />
            <CommonCalendarButtonWrapper>
              <CommonCalendarButtonUi
                backgroundColor={entity.selectedSlotBackgroundColor}
                onClick={onRescheduleBooking}
                disabled={isLoading}
              >
                {t('entity_settings.booking_calendar.reschedule_button_text')}
              </CommonCalendarButtonUi>
            </CommonCalendarButtonWrapper>
            {isErrorsVisible &&
              errors.map(error => (
                <FieldErrorUi key={error}>{error}</FieldErrorUi>
              ))}
          </>
        )}
      </CommonBookingCalendarContainerUi>
    </CommonBookingCalendarWrapperUi>
  )
}
