import React from 'react'
import 'react-day-picker/style.css'
import FieldErrorUi from 'common/components/core/FieldErrors/ui/FieldErrorUi'
import { FontProps } from 'common/utils/styleUtilsNew'
import { BookingCalendarStepsSwitcher } from './booking-calendar-steps-switcher'
import { CalendarStep, CalendarStepProps } from './calendar-step'
import {
  CommonCalendarButtonUi,
  CommonCalendarButtonWrapper,
} from './calendar-step/ui/common-calendar-button-ui'
import { BookingCalendarSteps } from './constants'
import { ThankYouStep } from './thank-you-step'
import {
  CommonBookingCalendarContainerUi,
  CommonBookingCalendarWrapperUi,
} from './ui/common-calendar-container-ui'

interface CommonBookingCalendarProps extends CalendarStepProps {
  onPrevStepClick?: () => void
  onFormSubmit?: (e: React.SyntheticEvent) => void
  calendarStepErrors?: string[]
  formStepErrors?: string[]
  isFormSubmitDisabled?: boolean

  isLoading?: boolean

  activeStep: BookingCalendarSteps
  formStepChildren: React.ReactNode
  hasThankYouStepButton: boolean
  thankYouStepRedirectToUrl?: (e: React.SyntheticEvent) => void
}

function CommonBookingCalendar({
  availabilitySlots,
  selectedDate,
  selectedMonth,
  onMonthSelect,
  onDateSelect,
  selectedTimeSlot,
  onTimeSlotSelect,
  selectedTimeZone,
  onTimeZoneSelect,
  selectedLocationId,
  onLocationSelect,
  event,
  isLoading,
  activeStep,
  entity,
  formStepChildren,
  onNextStepClick,
  onFormSubmit,
  onPrevStepClick,
  hasThankYouStepButton,
  thankYouStepRedirectToUrl,
  calendarStepErrors,
  formStepErrors,
  isFormSubmitDisabled,

  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  letterSpacing,
  mobileFontFamily,
  mobileFontSize,
  mobileFontStyle,
  mobileFontWeight,
  mobileLetterSpacing,
}: CommonBookingCalendarProps & FontProps) {
  const commonStyles = {
    color: entity.color,
    mobileColor: entity.mobileColor,
    mobilePadding: entity.mobilePadding,
    mobileMargin: entity.mobileMargin,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    letterSpacing,
    mobileFontFamily,
    mobileFontSize,
    mobileFontStyle,
    mobileFontWeight,
    mobileLetterSpacing,
  }

  return (
    <CommonBookingCalendarWrapperUi>
      <CommonBookingCalendarContainerUi
        padding={entity.padding}
        margin={entity.margin}
        isThankYouStep={activeStep === BookingCalendarSteps.ThankYouStep}
        {...commonStyles}
      >
        {activeStep === BookingCalendarSteps.ThankYouStep ? (
          <ThankYouStep
            entity={entity}
            selectedDate={selectedDate}
            selectedTimeSlot={selectedTimeSlot}
            selectedLocationId={selectedLocationId}
            selectedTimeZone={selectedTimeZone}
            event={event}
            hasThankYouStepButton={hasThankYouStepButton}
            thankYouStepRedirectToUrl={thankYouStepRedirectToUrl}
            {...commonStyles}
          />
        ) : (
          <>
            <BookingCalendarStepsSwitcher
              onPrevStepClick={onPrevStepClick}
              onNextStepClick={onNextStepClick}
              activeStep={activeStep}
              entity={entity}
              {...commonStyles}
            />
            {activeStep === BookingCalendarSteps.CalendarStep ? (
              <>
                <CalendarStep
                  availabilitySlots={availabilitySlots}
                  selectedDate={selectedDate}
                  selectedMonth={selectedMonth}
                  onMonthSelect={onMonthSelect}
                  onDateSelect={onDateSelect}
                  selectedTimeSlot={selectedTimeSlot}
                  onTimeSlotSelect={onTimeSlotSelect}
                  selectedTimeZone={selectedTimeZone}
                  onTimeZoneSelect={onTimeZoneSelect}
                  selectedLocationId={selectedLocationId}
                  onLocationSelect={onLocationSelect}
                  event={event}
                  isLoading={isLoading}
                  entity={entity}
                  {...commonStyles}
                />
                <CommonCalendarButtonWrapper>
                  <CommonCalendarButtonUi
                    backgroundColor={entity.selectedSlotBackgroundColor}
                    onClick={onNextStepClick}
                    {...commonStyles}
                  >
                    {entity.nextStepButtonText}
                  </CommonCalendarButtonUi>
                  {calendarStepErrors?.map(error => (
                    <FieldErrorUi key={error}>{error}</FieldErrorUi>
                  ))}
                </CommonCalendarButtonWrapper>
              </>
            ) : (
              <>
                {formStepChildren}
                <CommonCalendarButtonWrapper>
                  <CommonCalendarButtonUi
                    backgroundColor={entity.selectedSlotBackgroundColor}
                    onClick={onFormSubmit}
                    disabled={isFormSubmitDisabled}
                    {...commonStyles}
                  >
                    {entity.formButtonText}
                  </CommonCalendarButtonUi>
                  {formStepErrors?.map(error => (
                    <FieldErrorUi key={error}>{error}</FieldErrorUi>
                  ))}
                </CommonCalendarButtonWrapper>
              </>
            )}
          </>
        )}
      </CommonBookingCalendarContainerUi>
    </CommonBookingCalendarWrapperUi>
  )
}

export default CommonBookingCalendar
