import { useEffect, useState } from 'react'
import {
  BookingCalendarSteps,
  EventTimeZoneTypeEnum,
} from 'common/components/entities/Calendar/constants'
import { useEvent } from 'common/components/entities/Calendar/hooks/use-event'
import { getStartEndDates } from 'common/components/entities/Calendar/utils'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { getLocalTimeZone } from 'common/utils/date-utils'
import { useEventTimeSlots } from 'publisher/hooks/use-event-time-slots'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import { useCalendarSelectors} from './use-calendar-selectors'
export const useBookingCalendarParams = (entity: BookingCalendarInterface) => {
  const [activeStep, setActiveStep] = useState(
    BookingCalendarSteps.CalendarStep,
  )

  const {
    selectedDate,
    selectedTimeSlot,
    selectedLocationId,
    selectedTimeZone,
    handleChangeField,
  } = useCalendarSelectors()

  const { event, isFetching: isEventFetching } = useEvent({
    eventId: entity.eventId,
    scope: 'publisher',
  })
  const { timeSlots = [], isFetching: isTimeSlotsFetching } =
    useEventTimeSlots()

  useEffect(() => {
    if (event) {
      handleChangeField('eventId', event.id!)
      handleChangeField('ownerId', event.ownerId)
      handleChangeField('eventLocationId', event.locations?.[0]?.id)
      handleChangeField(
        'timeZone',
        event.displayTimezoneType === EventTimeZoneTypeEnum.Local
          ? getLocalTimeZone()
          : event.displayTimezone || getLocalTimeZone(),
      )
    }
  }, [event])

  const onMonthSelect = (month: Date) => {
    const { startDate, endDate } = getStartEndDates(month)
    handleChangeField('startDate', startDate)
    handleChangeField('endDate', endDate)
  }

  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const globalMobileFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalFontFamily = usePage(pageSelectors.getGlobalTextFontFamily)

  return {
    activeStep,
    setActiveStep,
    selectedDate,
    selectedTimeSlot,
    selectedTimeZone,
    selectedLocationId,
    handleChangeField,
    event,
    isEventFetching,
    timeSlots,
    isTimeSlotsFetching,
    onMonthSelect,
    globalFontSize,
    globalMobileFontSize,
    globalFontFamily,
  }
}
