import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonLink from 'common/components/entities/Button/LinkNew'
import { ButtonLinkTypeEnum } from 'common/enums/ButtonLinkEnum'
import { ButtonInterface } from 'common/types/entities/button-interface'
import useOptIn from 'publisher/hooks/useOptIn'
import { getNextStepUrl } from 'publisher/reducers/optInReducer'

const TEST_ID = 'next-step-redirection-button'

export interface NextStepRedirectionButtonProps {
  entity: ButtonInterface
}

const NextStepRedirectionButton = ({
  entity,
}: NextStepRedirectionButtonProps) => {
  const nextStepUrl = useOptIn(getNextStepUrl)

  return (
    <StyleWrapper
      styles={{ ...entity.margin, ...wrapperStyles }}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonLink
        dataTestId={TEST_ID}
        href={nextStepUrl}
        iconClassNameBefore={entity.iconClassNameBefore}
        iconClassNameAfter={entity.iconClassNameAfter}
        text={entity.text}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        subText={entity.subText}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
        background={entity.background ?? entity.backgroundColor}
        mobileBackground={
          entity.mobileBackground ?? entity.mobileBackgroundColor
        }
        attrId={entity.htmlAttrId}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
        textColor={entity.textColor}
        subTextColor={entity.subTextColor}
        mobileTextColor={entity.mobileTextColor}
        mobileSubTextColor={entity.mobileSubTextColor}
        textFontSize={entity.textFontSize}
        mobileTextFontSize={entity.mobileTextFontSize}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        subTextFontSize={entity.subTextFontSize}
        mobileSubTextFontSize={entity.mobileSubTextFontSize}
        textFontFamily={entity.textFontFamily}
        textFontWeight={entity.textFontWeight}
        textFontStyle={entity.textFontStyle}
        subTextFontFamily={entity.subTextFontFamily}
        subTextFontWeight={entity.subTextFontWeight}
        subTextFontStyle={entity.subTextFontStyle}
        mobileTextFontFamily={entity.mobileTextFontFamily}
        mobileTextFontWeight={entity.mobileTextFontWeight}
        mobileTextFontStyle={entity.mobileTextFontStyle}
        mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
        mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
        mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        hover={entity.hover}
        target={entity.linkTarget}
        mobileHover={entity.mobileHover}
      />
    </StyleWrapper>
  )
}

export default NextStepRedirectionButton
