import styled, { css } from 'styled-components'
import { PaddingType } from 'common/types/styleTypes'
import { mobilePaddingsCss, paddingsCss } from 'common/utils/styleUtilsNew'

export interface RemotePopupBodyScrollUiProps {
  padding?: PaddingType
  mobilePadding?: PaddingType
}

export const RemotePopupBodyScrollUi = styled.div<RemotePopupBodyScrollUiProps>`
  overflow-y: auto; // auto to prevent always showing scroll bar
  max-height: inherit;

  ${paddingsCss}

  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss}
    `}
`
