import React from 'react'
import { CloseRemotePopupBodyButtonUi } from './ui/close-remote-popup-body-button-ui'
import { RemotePopupBodyContentUi } from './ui/remote-popup-body-content-ui'
import {
  RemotePopupBodyScrollUi,
  RemotePopupBodyScrollUiProps,
} from './ui/remote-popup-body-scroll-ui'
import {
  RemotePopupBodyUi,
  RemotePopupBodyUiProps,
} from './ui/remote-popup-body-ui'

interface RemotePopupBodyProps
  extends Omit<RemotePopupBodyUiProps, 'color' | 'mobileColor'>,
    RemotePopupBodyScrollUiProps {
  children?: React.ReactNode

  onClose?: () => void
  showClose: boolean

  closeButtonColor?: string
  mobileCloseButtonColor?: string
}

export const CommonRemotePopupBody = ({
  showClose = false,
  onClose,
  children,

  background,
  mobileBackground,
  backgroundColor,
  mobileBackgroundColor,
  border,
  mobileBorder,
  boxShadow,
  mobileBoxShadow,
  padding,
  mobilePadding,
  closeButtonColor,
  mobileCloseButtonColor,
  maxHeight = 'calc(100vh - 40px)',
  overflow = 'visible',
}: RemotePopupBodyProps) => {
  return (
    <RemotePopupBodyUi
      maxHeight={maxHeight}
      overflow={overflow}
      background={background}
      mobileBackground={mobileBackground}
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      border={border}
      mobileBorder={mobileBorder}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
      color={closeButtonColor}
      mobileColor={mobileCloseButtonColor}
    >
      <RemotePopupBodyScrollUi padding={padding} mobilePadding={mobilePadding}>
        {showClose && (
          <CloseRemotePopupBodyButtonUi
            backgroundColor={closeButtonColor}
            mobileBackgroundColor={mobileCloseButtonColor}
            onClick={e => {
              e.preventDefault()
              onClose?.()
            }}
          />
        )}
        <RemotePopupBodyContentUi>{children}</RemotePopupBodyContentUi>
      </RemotePopupBodyScrollUi>
    </RemotePopupBodyUi>
  )
}
