export function isValidUrl(string, host) {
  try {
    new URL(string, host)
    return true
  } catch (_) {
    return false
  }
}

function getUrlHost(href) {
  const url = new URL(href)

  return url.host
}

function getUrlOrigin(href) {
  const url = new URL(href)

  return url.origin
}

function getUrlPath(href) {
  const url = new URL(href)

  return url.pathname + url.hash
}

export function getUrlPathList(href) {
  const url = new URL(href)

  return url.pathname.split('/').slice(1)
}

function getUrlHash(href) {
  const url = new URL(href)

  return url.hash
}

export function isUrlCurrent(url, currentUrl) {
  if (isValidUrl(url, getUrlOrigin(currentUrl))) {
    if (url[0] === '#') {
      return url === getUrlHash(currentUrl)
    } else {
      // we pass url origin in case of relative url, otherwise it will be ignored by URL api
      const { host, pathname, hash } = new URL(url, getUrlOrigin(currentUrl))

      return (
        host === getUrlHost(currentUrl) &&
        pathname + hash === getUrlPath(currentUrl)
      )
    }
  }

  return false
}

export function trimTrailingSlash(str) {
  return str.replace(/\/$/, '')
}

export function trimLeadingSlash(str) {
  return str.replace(/^\/+/g, '')
}

export function trimLeadingAndTrailingSlashes(str) {
  return str.replace(/^\/|\/$/g, '')
}

function isReplacement(url) {
  return url[0] === '%'
}
function isAnchor(url) {
  return url[0] === '#'
}

function isRelativeLink(url) {
  return url[0] === '/'
}

export function addUrlSchemaToTheLink(url) {
  // in case if url is null or undefined
  if (!url) {
    return ''
  }

  const urlSchemaRegexp = /^([a-z][a-z0-9+\-.]*):/
  if (!url.trim()) {
    return url.trim()
  }
  if (
    urlSchemaRegexp.test(url.trim()) ||
    isReplacement(url) ||
    isAnchor(url) ||
    isRelativeLink(url)
  ) {
    return url.trim()
  }
  return `https://${url.trim()}`
}
