import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { FontProps } from 'common/utils/styleUtilsNew'
import { EventInterface, EventLocationInterface } from '../calendar-interface'
import { EDITOR_DURATION_PLACEHOLDER } from '../calendar-step/event-info'
import {
  EventLocationTypeEnum,
  getReadableEventLocation,
  getReadableEventLocationIcon,
} from '../constants'
import { CalendarCheckIcon } from '../ui/icons/CalendarCheckIcon'
import { CameraIcon } from '../ui/icons/CameraIcon'
import { HourglassIcon } from '../ui/icons/HourglassIcon'
import { UserIcon } from '../ui/icons/UserIcon'
import { WorldIcon2 } from '../ui/icons/WorldIcon2'
import { formatDateTime } from '../utils'
import { BookingSummaryItemUi } from './ui/booking-summary-item-ui'
import { BookingSummaryUi } from './ui/booking-summary-ui'

export interface BookingSummaryProps {
  entity: BookingCalendarInterface
  event?: EventInterface
  selectedTimeZone: string
  selectedDate?: Date
  selectedLocationId?: EventLocationInterface['id']
  selectedTimeSlot?: number
}

export const BookingSummary = ({
  event,
  selectedTimeZone,
  selectedDate,
  selectedLocationId,
  selectedTimeSlot,
}: BookingSummaryProps & FontProps) => {
  const { t } = useTranslation()
  const details: {
    key: string
    value?: string | number
    icon: React.ElementType
  }[] = [
    {
      key: 'hostName',
      value: event?.hostName || t('components.core.calendar.empty_event_name'),
      icon: UserIcon,
    },
    {
      key: 'date',
      value:
        (selectedDate &&
          typeof selectedTimeSlot === 'number' &&
          formatDateTime(selectedDate, selectedTimeSlot, t)) ||
        '',
      icon: CalendarCheckIcon,
    },
    {
      key: 'duration',
      value: t(
        'entity_settings.booking_calendar.booking_summary.duration_minutes',
        {
          minutes: event?.eventDuration || EDITOR_DURATION_PLACEHOLDER,
        },
      ),
      icon: HourglassIcon,
    },
    {
      key: 'timeZone',
      value: selectedTimeZone,
      icon: WorldIcon2,
    },
  ]

  return (
    <BookingSummaryUi>
      <p style={{ fontWeight: 700, fontSize: '22px' }}>{event?.name}</p>
      {details.map(({ key, value, icon: Icon }) => (
        <BookingSummaryItemUi key={key}>
          <Icon />
          <span>{value}</span>
        </BookingSummaryItemUi>
      ))}
      <div>
        {!event?.locations && (
          <BookingSummaryItemUi>
            <CameraIcon />
            <span>
              {t(getReadableEventLocation(EventLocationTypeEnum.OnlineMeeting))}
            </span>
          </BookingSummaryItemUi>
        )}
        {event?.locations.map(({ locationType, id }) => {
          if (selectedLocationId !== id) return
          const Icon = getReadableEventLocationIcon(locationType)
          return (
            <BookingSummaryItemUi key={id}>
              <Icon />
              <span>{t(getReadableEventLocation(locationType))}</span>
            </BookingSummaryItemUi>
          )
        })}
      </div>
    </BookingSummaryUi>
  )
}
