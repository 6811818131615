import { BoxShadowInterface } from 'tools/commands/migrate/utils/migrateUtils'
import styled, { css } from 'styled-components'
import { BorderType, PaddingType } from 'common/types/styleTypes'
import {
  borderCss,
  boxShadowCss,
  fontCss,
  FontProps,
  mobileBackgroundColorCss,
  mobileBorderCss,
  mobileBorderRadiusCss,
  mobileBoxShadowCss,
  mobilePaddingsCss,
  paddingsCss,
} from 'common/utils/styleUtilsNew'

export type CommonInlineBodyUiProps = {
  width: number
  mobileWidth: number
  maxWidth: number
  padding?: PaddingType
  mobilePadding?: PaddingType
  mobileBackgroundImage?: string | null
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
} & FontProps &
  BoxShadowInterface

const CommonInlineBodyUi = styled.section<CommonInlineBodyUiProps>`
  margin: auto;
  width: 100%;
  position: relative;
  ${({ maxWidth, width }) =>
    css`
      max-width: ${maxWidth || width}px;
    `}

  ${paddingsCss}
  ${borderCss}
  ${boxShadowCss}
  ${fontCss}
  ${boxShadowCss}

  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
    ${mobileBorderCss}
    ${mobileBorderRadiusCss}
    ${mobileBoxShadowCss}
    ${mobileBackgroundColorCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss}
      ${mobileBorderCss}
      ${mobileBorderRadiusCss}
      ${mobileBoxShadowCss}
    `}
`

export default CommonInlineBodyUi
