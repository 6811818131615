import React, { SyntheticEvent } from 'react'
import { useDispatch } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonButton from 'common/components/entities/Button/Button'
import { ButtonInterface } from 'common/types/entities/button-interface'
import { showPopup } from 'publisher/actionsManagement'
import { getEntityById } from 'publisher/reducers/pageReducer'
import { usePage } from 'publisher/store'
import { dispatchPopupOpenedCustomEvent } from 'publisher/utils/popupUtils'

export const TEST_ID = 'show-popup-button'

export interface ShowPopupButtonProps {
  entity: ButtonInterface
}

const ShowPopupButton = ({ entity }: ShowPopupButtonProps) => {
  const dispatch = useDispatch()
  const popupEntity = usePage(page => getEntityById(page, entity.popup))

  const openPopup = (e: SyntheticEvent) => {
    e.preventDefault()
    dispatch(showPopup(entity.popup))
    // use small timeout in order to render popup
    if (popupEntity) {
      dispatchPopupOpenedCustomEvent(popupEntity.options.attrId)
    }
  }

  return (
    <StyleWrapper
      styles={{ ...entity.margin, ...wrapperStyles }}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonButton
        dataTestId={TEST_ID}
        iconClassNameBefore={entity.iconClassNameBefore}
        iconClassNameAfter={entity.iconClassNameAfter}
        onClick={openPopup}
        text={entity.text}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        subText={entity.subText}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
        background={entity.background ?? entity.backgroundColor}
        mobileBackground={
          entity.mobileBackground ?? entity.mobileBackgroundColor
        }
        attrId={entity.htmlAttrId}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
        textColor={entity.textColor}
        subTextColor={entity.subTextColor}
        mobileTextColor={entity.mobileTextColor}
        mobileSubTextColor={entity.mobileSubTextColor}
        textFontSize={entity.textFontSize}
        mobileTextFontSize={entity.mobileTextFontSize}
        subTextFontSize={entity.subTextFontSize}
        mobileSubTextFontSize={entity.mobileSubTextFontSize}
        textFontFamily={entity.textFontFamily}
        textFontWeight={entity.textFontWeight}
        textFontStyle={entity.textFontStyle}
        lineHeight={entity.lineHeight}
        subTextFontFamily={entity.subTextFontFamily}
        subTextFontWeight={entity.subTextFontWeight}
        subTextFontStyle={entity.subTextFontStyle}
        mobileTextFontFamily={entity.mobileTextFontFamily}
        mobileTextFontWeight={entity.mobileTextFontWeight}
        mobileTextFontStyle={entity.mobileTextFontStyle}
        mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
        mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
        mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
        mobileLineHeight={entity.mobileLineHeight}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        hover={entity.hover}
        mobileHover={entity.mobileHover}
      />
    </StyleWrapper>
  )
}

export default ShowPopupButton
